import React, { useContext, useEffect, useState } from 'react';
import type { StackParamList } from '../../navigation/navigation.types';
import styles from './styles.module.scss';

import mixins from '../../app/styles';
import { SocketContext } from '../../socket/socket.provider';

import { useReducer } from 'react';
import { useIsBusinessUser } from '../../hooks';
import { useMeQuery } from '#/api/api.portal';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { AuthContext } from '#/screens/auth/auth.utils';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { ROUTES } from './WebNavigationRoutes';
import { TargetPreference } from '#/socket/document.type';
import { getChannelsWithChatAvailable } from '#/utils/serverData';

export type Route = {
  name: string;
  screens: Array<keyof StackParamList>;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  activeIfStreaming?: boolean;
  hideForBusinessUsers?: boolean;
  hideForBusiness?: boolean;
  businessIndent?: boolean;
  showForTarget?: TargetPreference;
  showForChatsConfigured?: boolean;
};

export const WebNavigation: React.FC = () => {
  const navigation = useAppNavigation();
  const isBusinessUser = useIsBusinessUser();
  const { data: profile, isError: isMeError } = useMeQuery();
  const { webAuth } = useContext(AuthContext);
  const { selectedUserId } = useSelectedBusinessUserStore();
  const [mobileMenuVisible, toggleMobileMenu] = useReducer(state => !state, false);
  const {
    didLoadData,
    serverData,
    commonData: { isStreaming },
  } = useContext(SocketContext);
  const [routeBackTo, setRouteBackTo] = useState<keyof StackParamList | undefined>(undefined);
  const [currentRoute, setCurrentRoute] = useState<keyof StackParamList | undefined>(undefined);

  const handleNavigate = (screen: keyof StackParamList) => (e: any) => {
    e.preventDefault();

    if ((!didLoadData || !screen) && !webAuth?.admin) {
      return;
    }

    if (mobileMenuVisible) {
      toggleMobileMenu();
    }

    navigation.reset({
      routes: [
        {
          name: screen,
        },
      ],
    });
  };

  useEffect(() => {
    const saveNewRoute = () => {
      const state = navigation.getState();
      setCurrentRoute(state?.routes?.[state.index || 0]?.name || '');
    };

    navigation.addListener('state', saveNewRoute);

    saveNewRoute();
    return () => navigation.removeListener('state', saveNewRoute);
  }, [navigation]);

  useEffect(() => {
    if (
      !didLoadData &&
      currentRoute &&
      currentRoute !== 'Connect' &&
      !(webAuth?.admin && !isMeError)
    ) {
      if (currentRoute !== 'SignIn') {
        setRouteBackTo(currentRoute);
      }

      navigation.navigate('Connect', {});
    } else if (didLoadData && routeBackTo) {
      setRouteBackTo(undefined);
      navigation.navigate(routeBackTo as any);
    }
  }, [navigation, currentRoute, routeBackTo, didLoadData]);

  const routesToShow = ROUTES.filter(route => {
    if (isBusinessUser) {
      return !route.hideForBusinessUsers;
    }

    if (profile?.business && route.hideForBusiness) {
      return false;
    }

    if (route.showForChatsConfigured) {
      const targetsWithChats = getChannelsWithChatAvailable(serverData);
      const hasChats = targetsWithChats.length > 0;

      if (hasChats) {
        return true;
      }
    }

    if (
      route.showForTarget !== undefined &&
      route.showForTarget !== serverData?.Settings?.TargetPreference
    ) {
      return false;
    }

    return true;
  });

  return (
    <>
      <button type="button" onClick={toggleMobileMenu} className={styles.navToggle}>
        ☰
      </button>
      <div
        className={[styles.navigation, mobileMenuVisible && styles.navigationMobileVisible]
          .filter(Boolean)
          .join(' ')}
      >
        {routesToShow.map(
          ({ name, screens, href, icon: Icon, activeIfStreaming, businessIndent }) => {
            const externalLink = href.startsWith('http');
            const disabled =
              (!screens[0] && !externalLink) ||
              !currentRoute ||
              ['Connect', 'ThanksForSubscribing'].includes(currentRoute) ||
              (businessIndent && webAuth?.admin && !selectedUserId);

            const linkStyles = [
              styles.link,
              screens.includes(currentRoute!) && styles.linkActive,
              disabled && styles.linkInactive,
              activeIfStreaming && !profile?.business && isStreaming && styles.linkGreen,
              businessIndent && (profile?.business || webAuth?.admin) && styles.indent,
            ]
              .filter(Boolean)
              .join(' ');

            return (
              <a
                key={name}
                href={disabled ? undefined : href}
                target={externalLink ? '_blank' : '_self'}
                onClick={externalLink || disabled ? undefined : handleNavigate(screens[0])}
                className={linkStyles}
              >
                <Icon fill={mixins.color.white} />
                <span>{name}</span>
              </a>
            );
          },
        )}
      </div>
    </>
  );
};
