import { AutoLoginState, Document, PlatformInfoFlags } from '#/socket/document.type';
import { getDeviceId } from '#/utils/device';

export const getWebRtcOutgest = (serverData: Document) =>
  Object.values(serverData.Outgests).find(
    v => v.Data.Type === 'webrtc' && v.Data.DeviceId === getDeviceId(),
  );

export const getChannelsWithChat = (serverData: Document) =>
  Object.values(serverData.Channels).filter(channel => {
    const platform = serverData.Platforms.PlatformInfos.find(s => s.TargetId == channel.TargetId);
    const platformSupportsChat =
      (platform && (platform.Flags & PlatformInfoFlags.Chats) > 0) ?? false;

    return (
      channel.AutoLoginState === AutoLoginState.KeyObtained &&
      serverData.Targets[channel.TargetId]?.Flags &&
      platformSupportsChat
    );
  });

export const getChannelsWithChatAvailable = (serverData: Document) =>
  Object.values(serverData.Channels).filter(channel => {
    const platform = serverData.Platforms.PlatformInfos.find(s => s.TargetId == channel.TargetId);
    const platformSupportsChat =
      (platform && (platform.Flags & PlatformInfoFlags.Chats) > 0) ?? false;

    return serverData.Targets[channel.TargetId]?.Flags && platformSupportsChat;
  });
